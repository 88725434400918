import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { DataGrid, } from "@mui/x-data-grid";
import { useAppDispatch } from "./store";
import { metabolites } from "./reducers";
import { useReactionsQuery } from "./api/metabolites";
const PAGE_SIZE = 25;
const ReactionsView = (props) => {
    return (_jsx("div", { children: props.reactions.map((reaction, i) => {
            return i === 0 ? (_jsx("span", { style: { margin: 5 }, children: reaction }, i)) : (_jsxs("span", { children: [_jsx("span", { style: { margin: 5 }, children: "\u279D" }), _jsx("span", { children: reaction })] }, i));
        }) }));
};
const expand = (compressed) => {
    if (compressed.length === 0) {
        return [];
    }
    else {
        const tail = expand(compressed.slice(1));
        return (compressed[0] ?? [])
            .map((zero) => tail.length === 0
            ? zero
            : tail.map((t) => Array.isArray(t) ? [...zero, ...t] : [...zero, t]))
            .flat();
    }
};
export const Reactions = ({ tracer, gauge, pathway, }) => {
    const [page, setPage] = useState(0);
    const handlePaginationModelChange = (model) => {
        setPage(model.page);
    };
    const dispatch = useAppDispatch();
    const { data, isLoading } = useReactionsQuery({
        tracer: tracer.id,
        gauge: gauge.id,
        path: pathway,
    });
    const expanded = expand(data?.compressed ?? []);
    const names = new Map(Object.entries(data?.reactions ?? []));
    const handleSelection = (newModel) => {
        const index = newModel[0];
        if (index !== undefined) {
            dispatch(metabolites.actions.setReactionPath({
                reactions: expanded[index] ?? [],
                index: index,
            }));
        }
        else {
            console.error("Attempted to use an invalid expanded path index");
        }
    };
    const rows = expanded
        .map((reactions, i) => ({
        id: i,
        reactions: reactions.map((name) => names.get(name) ?? name),
    }))
        .slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);
    const columns = [
        {
            field: "reactions",
            headerName: "Reactions",
            flex: 1,
            renderCell: (params) => (_jsx(ReactionsView, { reactions: (params.value ?? []) })),
        },
    ];
    return (_jsx("div", { style: { height: 400, width: "100%" }, children: _jsx(DataGrid, { rows: rows, columns: columns, loading: isLoading, pagination: true, paginationModel: { page, pageSize: PAGE_SIZE }, pageSizeOptions: [PAGE_SIZE], onPaginationModelChange: handlePaginationModelChange, getRowHeight: () => "auto", onRowSelectionModelChange: handleSelection }) }));
};
