import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import * as R from "ramda";
import { PathwaySummary } from "./PathwaySummary";
export const Results1 = ({ pathway, reaction, labeling, }) => {
    return (_jsxs("div", { style: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            justifyContent: "center",
        }, children: [_jsx(PathwaySummary, { pathway: pathway, reaction: reaction }), _jsx("img", { style: {
                    maxWidth: "100%",
                }, src: `${SERVICE_URL}/results/${pathway}.svg?labeled_atoms=${R.join(",", labeling)}&reaction=${reaction}`, alt: "Pathway result" })] }));
};
const displayExperiment = (which, props) => {
    if (which === 0 &&
        props.pathway !== undefined &&
        props.reaction !== undefined &&
        props.labeling.length > 0) {
        return (_jsx(Results1, { pathway: props.pathway, reaction: props.reaction, labeling: props.labeling }));
    }
    else {
        return _jsx(_Fragment, { children: "Not an experiment" });
    }
};
export const Results = (props) => {
    const [which, setWhich] = React.useState(0);
    return (_jsxs(_Fragment, { children: [_jsxs(ButtonGroup, { variant: "contained", "aria-label": "outlined primary button group", children: [_jsx(Button, { onClick: () => {
                            setWhich(0);
                        }, children: "Static SVG" }), _jsx(Button, { disabled: true, children: "Interactive" })] }), displayExperiment(which, props)] }));
};
