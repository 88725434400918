import { jsx as _jsx } from "react/jsx-runtime";
import { createSelector } from "@reduxjs/toolkit";
import { useAppSelector } from "./store";
import { TracerSelector } from "./TracerSelector";
import { GaugeSelector } from "./GaugeSelector";
import { LabelCounts } from "./LabelCounts";
import { PathwayCounts } from "./PathwayCounts";
import { AtomSelector } from "./AtomSelector";
import { Pathways } from "./Pathways";
import { Reactions } from "./Reactions";
const stepSelector = createSelector((state) => state.metabolites.tracer, (state) => state.metabolites.gauge, (state) => state.metabolites.tracer_labeling, (state) => state.metabolites.label_count, (state) => state.metabolites.pathway_count, (state) => state.metabolites.pathway, (tracer, gauge, labeling, label_count, pathway_count, pathway) => ({
    tracer,
    gauge,
    labeling,
    label_count,
    pathway_count,
    pathway,
}));
export const TracerFateFormStep = (props) => {
    const selections = useAppSelector(stepSelector);
    if (props.step === 0 || selections.tracer === undefined) {
        return _jsx(TracerSelector, {});
    }
    else if (props.step === 1 || selections.labeling.length === 0) {
        return _jsx(AtomSelector, { metabolite: selections.tracer });
    }
    else if (props.step === 2 || selections.gauge === undefined) {
        return _jsx(GaugeSelector, { tracer: selections.tracer });
    }
    else if (props.step === 3 || selections.label_count === undefined) {
        return (_jsx(LabelCounts, { tracer: selections.tracer, labeling: selections.labeling, gauge: selections.gauge }));
    }
    else if (props.step === 4 || selections.pathway_count === undefined) {
        return (_jsx(PathwayCounts, { tracer: selections.tracer, labeling: selections.labeling, gauge: selections.gauge, label_count: selections.label_count }));
    }
    else if (props.step === 5 || selections.pathway === undefined) {
        return (_jsx(Pathways, { tracer: selections.tracer, labeling: selections.labeling, gauge: selections.gauge, label_count: selections.label_count, pathway_count: selections.pathway_count }));
    }
    else if (props.step === 6) {
        return (_jsx(Reactions, { tracer: selections.tracer, gauge: selections.gauge, pathway: selections.pathway }));
    }
    else {
        return _jsx("div", { children: `Unknown step: ${props.step}` });
    }
};
