import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CircularProgress from "@mui/material/CircularProgress";
import { useSummaryQuery } from "./api/results";
import "./PathwaySummary.css";
const SummaryMetabolite = ({ name, left, right, }) => {
    if (left === null && right === null) {
        return _jsx("span", { children: name });
    }
    else if (left === null) {
        return (_jsx("span", { style: {
                background: `linear-gradient(90deg, transparent 50%, ${right} 50%)`,
            }, children: name }));
    }
    else if (right === null) {
        return (_jsx("span", { style: {
                background: `linear-gradient(90deg, ${left} 50%, transparent 50%)`,
            }, children: name }));
    }
    else {
        return (_jsx("span", { style: {
                background: `linear-gradient(90deg, ${left} 50%, ${right} 50%)`,
            }, children: name }));
    }
};
const SummaryReaction = ({ name, colour }) => {
    const background = colour ?? "transparent";
    return (_jsxs("span", { style: { background }, children: ["\u00A0\u2192\u00A0", _jsx("span", { className: "summary-reaction", children: name }), "\u00A0\u2192\u00A0"] }));
};
export const PathwaySummary = (props) => {
    const { data, isLoading } = useSummaryQuery(props);
    if (isLoading) {
        return (_jsx("div", { className: "pathway-summary-container", children: _jsx(CircularProgress, {}) }));
    }
    else {
        return (_jsxs("div", { className: "pathway-summary-container", children: [_jsx("hr", {}), _jsx("div", { className: "reaction-summary", children: data?.summary.map((el, i) => el.what === "metabolite" ? (_jsx(SummaryMetabolite, { name: el.name, left: el.left, right: el.right }, i)) : (_jsx(SummaryReaction, { name: el.name, colour: el.colour }, i))) }), _jsx("hr", {}), _jsx("div", { className: "pathway-summary", children: data?.pathways.map((pathway, i) => (_jsx("div", { className: "pathway-item", style: { background: `${pathway.colour}` }, children: pathway.name }, `pathway-${i}`))) })] }));
    }
};
