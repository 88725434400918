import { api } from "./api";
function fromSummaryElement(element) {
    if (element.what === "reaction") {
        return {
            what: element.what,
            name: element.name,
            colour: element.colour?.toString() ?? null,
        };
    }
    else {
        return {
            what: element.what,
            name: element.name,
            left: element.colour?.at(0) ?? null,
            right: element.colour?.at(1) ?? null,
        };
    }
}
const resultsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        summary: builder.query({
            query: (args) => ({
                url: `/results/summary?pathway=${args.pathway}&reaction=${args.reaction}`,
                method: "GET",
            }),
            transformResponse: (response) => {
                return {
                    summary: response.summary.map(fromSummaryElement),
                    pathways: response.pathways,
                };
            },
        }),
    }),
});
export const { useSummaryQuery } = resultsApi;
