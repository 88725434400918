import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { DataGrid, } from "@mui/x-data-grid";
import { useAppDispatch } from "./store";
import { useMetabolitesQuery } from "./api/metabolites";
import { metabolites } from "./reducers";
const PAGE_SIZE = 25;
const Metabolite = (props) => {
    const color = props.metabolite.input_for_next_step ? "blue" : "inherit";
    const name = props.metabolite.common_name
        ? props.metabolite.common_name
        : props.metabolite.name;
    return _jsx("span", { style: { color, margin: 5 }, children: name });
};
const PathwayElement = (props) => {
    return (_jsxs("span", { children: ["(", props.element
                .map((metabolite, i) => _jsx(Metabolite, { metabolite: metabolite }, i))
                .flatMap((e) => [", ", e])
                .slice(1), ")"] }));
};
const PathwayView = (props) => {
    return (_jsx("div", { children: props.datum
            .map((p, i) => (_jsx(PathwayElement, { element: p }, i)))
            .flatMap((e, i) => [
            _jsx("span", { style: { margin: 5 }, children: "\u279D" }, `arrow-${i}`),
            e,
        ])
            .slice(1) }));
};
export const Pathways = ({ tracer, labeling, gauge, label_count, pathway_count, }) => {
    const mapPageToNextCursor = useRef({});
    const [page, setPage] = useState(0);
    const handlePaginationModelChange = (model) => {
        if (model.page === 0 || mapPageToNextCursor.current[model.page - 1]) {
            setPage(model.page);
        }
    };
    const dispatch = useAppDispatch();
    const handleSelection = (newModel) => {
        const pid = newModel[0];
        if (pid !== undefined) {
            dispatch(metabolites.actions.setPathway(pid));
        }
        else {
            console.error("Attempted to set invalid pathway id");
        }
    };
    const { data, isLoading } = useMetabolitesQuery({
        tracer: tracer.id,
        gauge: gauge.id,
        labels: labeling,
        label_count: label_count,
        num_pathways: pathway_count,
        offset: mapPageToNextCursor.current[page - 1],
        limit: PAGE_SIZE,
    });
    useEffect(() => {
        if (!isLoading && data !== undefined) {
            mapPageToNextCursor.current[page] = data.nextCursor;
        }
    }, [page, isLoading, data]);
    const rows = (data?.paths ?? []).map((pathway) => ({
        id: pathway.id,
        path: pathway.path,
    }));
    const columns = [
        {
            field: "path",
            headerName: "Metabolites",
            flex: 1,
            renderCell: (params) => (_jsx(PathwayView, { datum: (params.value ?? []) })),
        },
    ];
    return (_jsx("div", { style: { height: 400, width: "100%" }, children: _jsx(DataGrid, { rows: rows, columns: columns, loading: isLoading, pagination: true, paginationMode: "server", paginationModel: { page, pageSize: PAGE_SIZE }, pageSizeOptions: [PAGE_SIZE], rowCount: data?.total ?? 0, onPaginationModelChange: handlePaginationModelChange, getRowHeight: () => "auto", keepNonExistentRowsSelected: true, onRowSelectionModelChange: handleSelection }) }));
};
